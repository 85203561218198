import React from 'react';
import {
  Modal,
  Tab,
} from '@jvs-group/jvs-mairistem-composants';
import type Simulation from '../../../Simulation/interfaces/simulation';
import ImportEmprunt from './ImportEmprunt';
import './ImportModal.less';
import ImportBien from './ImportBien';

interface ImportModalProps {
  open: boolean;
  onClose: () => void;
  onValidate: () => void,
  simulation: Simulation;
}

const ImportModal = ({
  open,
  onValidate,
  onClose,
  simulation,
}: ImportModalProps) => {
  const handleClose = () => onClose?.();

  const PANES = [
    {
      menuItem: 'Gestion des emprunts',
      render: () => (
        <Tab.Pane>
          <ImportEmprunt
            simulation={simulation}
            onClose={handleClose}
            onValidate={onValidate}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Gestion des biens',
      render: () => (
        <Tab.Pane>
          <ImportBien
            simulation={simulation}
            onClose={handleClose}
            onValidate={onValidate}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      size="fullscreen"
      open={open}
    >
      <Modal.Header content="Alimenter les propositions à partir des logiciels métiers" />
      <Modal.Content>
        <Tab
          grid={{ tabWidth: 2, paneWidth: 14 }}
          menu={{
            pointing: true,
            secondary: true,
            vertical: true,
          }}
          panes={PANES}
        />
      </Modal.Content>
    </Modal>
  );
};

export default ImportModal;
