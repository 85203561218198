import React from 'react';
import ImportModal from '../components/Import/ImportModal';
import BaseOutil from './BaseOutil';
import type Simulation from '../../Simulation/interfaces/simulation';

class RecuperationDonneeConnexe extends BaseOutil {
  constructor() {
    super();
    this.code = 'RECUP_DONN_CONN';
    this.icon = {
      iconSet: 'Lucide',
      name: 'Share2',
    };
  }

  title = 'Alimenter les propositions à partir des logiciels métiers';

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: () => void,
    open: boolean,
  ) {
    return (
      <ImportModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default RecuperationDonneeConnexe;
