import TypeBudget from '../../Simulation/enums/typeBudget';
import BalanceFonctionnementPanel from '../classes/BalanceFonctionnementPanel';
import BalanceInvestissementPanel from '../classes/BalanceInvestissementPanel';
import ChargePersonnelPanel from '../classes/ChargePersonnelPanel';
import EpargneBrutePanel from '../classes/EpargneBrutePanel';
import EpargneNettePanel from '../classes/EpargneNettePanel';
// TODO: Remettre en constante PANEL_LIST quand correction back fait
const getPanelList = (typeBudget: TypeBudget) => [
  new BalanceFonctionnementPanel(),
  new BalanceInvestissementPanel(),
  ...([TypeBudget.BUDGET_PRIMITIF, TypeBudget.COMPTE_ADMINISTRATIF].includes(typeBudget)
    ? [new ChargePersonnelPanel()]
    : []
  ),
  ...([TypeBudget.BUDGET_PRIMITIF, TypeBudget.COMPTE_ADMINISTRATIF].includes(typeBudget)
    ? [new EpargneBrutePanel()]
    : []
  ),
  ...([TypeBudget.BUDGET_PRIMITIF, TypeBudget.COMPTE_ADMINISTRATIF].includes(typeBudget)
    ? [new EpargneNettePanel()]
    : []
  ),
];

export default getPanelList;
